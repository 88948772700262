var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-item',{attrs:{"label":"内部API服务器域名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.api_server_domain', {
        normalize: this.$lodash.trim,
        rules: [{ required: true, message: '请输入内部API服务器域名' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_mobile_api_account_form.api_server_domain', {\n        normalize: this.$lodash.trim,\n        rules: [{ required: true, message: '请输入内部API服务器域名' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{attrs:{"label":"使用中国移动接口"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.is_use_standard_api']),expression:"['china_mobile_api_account_form.is_use_standard_api']"}],attrs:{"checked":_vm.isUseStandardApi},on:{"change":_vm.changeIsUseStandardApi}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUseStandardApi),expression:"isUseStandardApi"}]},[_c('a-form-item',{attrs:{"label":"中国移动接口版本"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.standard_api_version', {
          rules: [{ required: _vm.isUseStandardApi, message: '请选择中国移动接口版本' }
          ]
        }]),expression:"['china_mobile_api_account_form.standard_api_version', {\n          rules: [{ required: isUseStandardApi, message: '请选择中国移动接口版本' }\n          ]\n        }]"}]},[_c('a-select-option',{key:"V5",attrs:{"value":"V5"}},[_vm._v(" V5 ")]),_c('a-select-option',{key:"V2",attrs:{"value":"V2"}},[_vm._v(" V2 ")])],1)],1),_c('a-form-item',{attrs:{"label":"API访问路径"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.standard_api_url', {
          normalize: this.$lodash.trim,
          rules: [{ required: _vm.isUseStandardApi, message: '请输入API访问路径' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]),expression:"['china_mobile_api_account_form.standard_api_url', {\n          normalize: this.$lodash.trim,\n          rules: [{ required: isUseStandardApi, message: '请输入API访问路径' },\n                  { max: 50, message: '最多50个字符' }\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"应用编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.app_id', {
          normalize: this.$lodash.trim,
          rules: [{ required: _vm.isUseStandardApi, message: '请输入应用编码' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]),expression:"['china_mobile_api_account_form.app_id', {\n          normalize: this.$lodash.trim,\n          rules: [{ required: isUseStandardApi, message: '请输入应用编码' },\n                  { max: 50, message: '最多50个字符' }\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"接口密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.api_password', {
          normalize: this.$lodash.trim,
          rules: [{ required: _vm.isUseStandardApi, message: '请输入接口密码' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]),expression:"['china_mobile_api_account_form.api_password', {\n          normalize: this.$lodash.trim,\n          rules: [{ required: isUseStandardApi, message: '请输入接口密码' },\n                  { max: 50, message: '最多50个字符' }\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"调用限频"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '55%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.standard_rate_count', {
                rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]
              }]),expression:"['china_mobile_api_account_form.standard_rate_count', {\n                rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]\n              }]"}],staticStyle:{"width":"135px"},attrs:{"min":1,"max":10000000}}),_c('span',[_vm._v("次")])],1)],1),_c('a-form-item',{style:({ display: 'inline-block', width: '40%', marginBottom: 0 })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.standard_rate_time_unit', {initialValue: 'second'}]),expression:"['china_mobile_api_account_form.standard_rate_time_unit', {initialValue: 'second'}]"}],staticStyle:{"width":"140px"}},[_c('a-select-option',{attrs:{"value":"second"}},[_vm._v(" 每秒 ")]),_c('a-select-option',{attrs:{"value":"minute"}},[_vm._v(" 每分 ")])],1)],1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"使用地方移动接口"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.is_use_province_api']),expression:"['china_mobile_api_account_form.is_use_province_api']"}],attrs:{"checked":_vm.isUseProvinceApi},on:{"change":_vm.changeIsUseProvinceApi}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUseProvinceApi),expression:"isUseProvinceApi"}]},[_c('a-form-item',{attrs:{"label":"地方接口所属区域"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.province_api_type', {
          rules: [{ required: _vm.isUseProvinceApi, message: '请选择地方接口所属区域' }]
        }]),expression:"['china_mobile_api_account_form.province_api_type', {\n          rules: [{ required: isUseProvinceApi, message: '请选择地方接口所属区域' }]\n        }]"}],on:{"change":_vm.changeProvinceApiType}},_vm._l((_vm.provinceApiTypeOptions),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"配置参数"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.province_api_config', {
          normalize: this.$lodash.trim,
          rules: [{ required: _vm.isUseProvinceApi, message: '请输入配置参数' },
                  { max: 200, message: '最多200个字符' }
          ]
        }]),expression:"['china_mobile_api_account_form.province_api_config', {\n          normalize: this.$lodash.trim,\n          rules: [{ required: isUseProvinceApi, message: '请输入配置参数' },\n                  { max: 200, message: '最多200个字符' }\n          ]\n        }]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 },"placeholder":_vm.provinceApiConfigPlaceholder}})],1),_c('a-form-item',{attrs:{"label":"调用限频"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '55%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.province_rate_count', {
                rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]
              }]),expression:"['china_mobile_api_account_form.province_rate_count', {\n                rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]\n              }]"}],staticStyle:{"width":"135px"},attrs:{"min":1,"max":10000000}}),_c('span',[_vm._v("次")])],1)],1),_c('a-form-item',{style:({ display: 'inline-block', width: '40%', marginBottom: 0 })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_mobile_api_account_form.province_rate_time_unit', {initialValue: 'second'}]),expression:"['china_mobile_api_account_form.province_rate_time_unit', {initialValue: 'second'}]"}],staticStyle:{"width":"140px"}},[_c('a-select-option',{attrs:{"value":"second"}},[_vm._v(" 每秒 ")]),_c('a-select-option',{attrs:{"value":"minute"}},[_vm._v(" 每分 ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }